import React, { FC, useEffect } from "react";
import { DialogProps } from "@material-ui/core/Dialog";
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  createStyles,
  Theme,
  IconButton
} from "@material-ui/core";
import RHCloseIcon from "icon-close";
import RHDialogTitle from "component-rh-dialog-title";
import { useTheme } from "utils/material-ui-core";

interface StyleProps {
  hideScroll?: boolean;
  dialogContentClassName?: string;
  dialogActionsClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      "&.Mui-focusVisible": {
        outline: "1px solid #000000",
        outlineOffset: "2px"
      }
    },
    content: {
      "& > div:first-child": {
        padding: 0
      },
      paper: { margin: 0 }
    }
  })
);

export const FormDialog: FC<DialogProps & StyleProps> = props => {
  const {
    hideScroll = false,
    dialogContentClassName,
    dialogActionsClassName
  } = props;
  const classes = useStyles();
  const paperProps = {
    className: "!max-w-[741px] w-[calc(100%-32px)]",
    square: true
  };

  const theme = useTheme();

  useEffect(() => {
    if (props.open) {
      const scrollPosition = window.pageYOffset;

      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `-${scrollPosition}px`;

      return () => {
        document.body.style.overflow = "";
        document.body.style.position = "";
        document.body.style.width = "";
        document.body.style.top = "";
        window.scrollTo(0, scrollPosition);
      };
    }
  }, [props.open]);

  return (
    <Dialog
      id={props?.id || "layout-form-dialog"}
      PaperProps={props?.PaperProps || paperProps}
      fullWidth
      BackdropProps={props?.BackdropProps}
      maxWidth={false}
      open={props.open}
      onBackdropClick={event => props.onClose?.(event, "escapeKeyDown")}
      onEscapeKeyDown={event => props.onClose?.(event, "escapeKeyDown")}
      disableScrollLock
      style={{ background: "rgba(0,0,0,0.5)" }}
      tabIndex={0}
    >
      {!!props.title ? (
        <RHDialogTitle
          title={props.title}
          onBack={event => props.onClose?.(event, "escapeKeyDown")}
        />
      ) : (
        <DialogActions
          className={`h-[32px] !p-0 !items-start sm:h-[56px] xl:h-[75px] z-10 ${dialogActionsClassName}`}
        >
          <IconButton
            disableRipple
            className={`w-8 h-8 !rounded-none sm:w-[50px] sm:h-[50px] ${classes.iconButton}`}
            color="inherit"
            onClick={event => props.onClose?.(null, "escapeKeyDown")}
            data-testid="form-dialog-close-button"
            autoFocus
            aria-label="Close"
          >
            <RHCloseIcon className={"!text-[14px] sm:!text-[20px]"} />
          </IconButton>
        </DialogActions>
      )}
      <DialogContent
        className={`${
          dialogContentClassName
            ? dialogContentClassName
            : `!p-0 !px-8 !pb-8 sm:!px-6 sm:!pb-12 md:!px-12 md:!pb-12 xl:!px-16 xl:!pb-16 xl:!m-0`
        }
          ${
            hideScroll ? "!overflow-y-hidden" : "!overflow-y-auto"
          } !overflow-x-hidden ${classes.content}`}
      >
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
